const firebaseConfig = {
  apiKey: "AIzaSyBlH1Flnha58hUHym5ycDx2GtRos9EvnTI",
  authDomain: "step-fall-rise.firebaseapp.com",
  projectId: "step-fall-rise",
  storageBucket: "step-fall-rise.appspot.com",
  messagingSenderId: "580060684029",
  appId: "1:580060684029:web:d2b9353034194675ac2894",
  measurementId: "G-SPFB7N5EDD",
};

export default firebaseConfig;
