import React from "react";
import ReactDOM from "react-dom";
import { FirebaseContext } from "./context/firebase";
import App from "./App";
import firebase from "./firebase/firebase.prod.js";
import "./index.css";
import GlobalStyles from "./styles/GlobalStyles";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://6b2b52a97d0e4f20901c08c73c488180@o1130775.ingest.sentry.io/6174899",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <FirebaseContext.Provider value={{ firebase }}>
    <React.StrictMode>
      <GlobalStyles />
      <App />
    </React.StrictMode>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
