import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useHistory, useLocation } from "react-router-dom";
import { MenuButton } from "./styles";

const Box = () => {
  gsap.registerPlugin(ScrollTrigger);
  let history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const tl = gsap.timeline({
      duration: 1,
      stagger: {
        amount: 1,
        grid: [10, 10],
        from: "center",
      },
    });
    tl.to(".menu", { opacity: 1 })
      .from(".box", {
        scale: 0.1,
        y: 20,
        yoyo: true,
        repeat: 2,
        color: "salmon",
        ease: "power1.inOut",
        delay: 0.2,
        stagger: {
          amount: 1,
          grid: [10, 10],
          from: "edges",
        },
      })
      .to(".box", {
        rotate: 360,
        duration: 1,
        stagger: {
          amount: 1,
          grid: [10, 10],
          from: "center",
        },
      })
      .to(".box", {
        scale: 0.2,
        // yoyo: true,
        // repeat: 2,
        ease: "power1.inOut",
        delay: 0.1,
        stagger: {
          amount: 1,
          grid: [10, 10],
          from: "center",
        },
      })
      .to(".box", {
        scale: 0.7,
        duration: 1,
        stagger: {
          amount: 1,
          grid: [10, 10],
          from: "center",
        },
      });
  }, []);

  const homeBtn = useRef(null);
  const aboutBtn = useRef(null);
  const artistsBtn = useRef(null);
  const watchBtn = useRef(null);
  const donateBtn = useRef(null);

  const home = gsap.timeline({ paused: true });
  const about = gsap.timeline({ paused: true });
  const artists = gsap.timeline({ paused: true });
  const watch = gsap.timeline({ paused: true });
  const donate = gsap.timeline({ paused: true });

  useEffect(() => {
    const animProps = { x: 20, ease: "easeOut", color: "black" };
    home.to(homeBtn.current, animProps).addPause();
    about.to(aboutBtn.current, animProps).addPause();
    artists.to(artistsBtn.current, animProps).addPause();
    watch.to(watchBtn.current, animProps).addPause();
    donate.to(donateBtn.current, animProps).addPause();
  }, [home, about, artists, watch, donate, pathname]);

  function openMenu() {
    return gsap
      .timeline()
      .to(".open-menu", {
        opacity: 1,
        width: "100%",
        autoAlpha: 1,
        zIndex: 9999,
      })
      .to(
        [
          homeBtn.current,
          aboutBtn.current,
          artistsBtn.current,
          watchBtn.current,
          donateBtn.current,
        ],
        {
          opacity: 1,
          y: 20,
          stagger: 0.05,
          ease: "bounce",
        }
      );
  }

  function closeMenu() {
    return gsap
      .timeline()
      .to(
        [
          homeBtn.current,
          aboutBtn.current,
          artistsBtn.current,
          watchBtn.current,
          donateBtn.current,
        ],
        {
          opacity: 0,
          y: -20,
          stagger: 0.05,
          ease: "easeOut",
        }
      )
      .to(".open-menu", { opacity: 0, width: "0%", autoAlpha: 0 });
  }

  return (
    <div className="">
      <div
        className="grid grid-cols-5 gap-1 place-items-start fixed top-3 left-3 opacity-0 menu cursor-pointer"
        onClick={openMenu}
      >
        {[...Array<any>(25).keys()].map((a) => {
          return (
            <div
              key={Math.random() * 1000}
              className="box-menu w-1 h-1 opacity-70 box z-50 bg-appOrange-200"
            ></div>
          );
        })}
      </div>

      <div className="z-40 flex h-full align-items-center bg-gradient-to-b from-appOrange-400 to-appOrange-200 justify-center open-menu opacity-0 flex-col text-center w-0 fixed overflow-y-scroll mb-40 pb-20 pt-64">
        <div
          className="absolute top-14 right-14 font-monoton cursor-pointer text-4xl text-white z-20"
          onClick={closeMenu}
        >
          X
        </div>

        <MenuButton
          ref={homeBtn}
          onClick={
            pathname !== "/"
              ? () => {
                  closeMenu();
                  home.reverse();
                  history.push("/");
                }
              : () => {}
          }
          onMouseOver={() => home.play()}
          onMouseLeave={() => home.reverse()}
        >
          HOME
        </MenuButton>

        <MenuButton
          ref={aboutBtn}
          onClick={() => {
            closeMenu();
            about.reverse();
            history.push("/about-the-project");
          }}
          onMouseOver={() => about.play()}
          onMouseLeave={() => about.reverse()}
        >
          ABOUT
        </MenuButton>

        <MenuButton
          ref={artistsBtn}
          onClick={() => {
            closeMenu();
            artists.reverse();
            history.push("/artists");
          }}
          onMouseOver={() => artists.play()}
          onMouseLeave={() => artists.reverse()}
        >
          ARTISTS
        </MenuButton>

        <MenuButton
          ref={watchBtn}
          onClick={() => {
            closeMenu();
            watch.reverse();
            history.push("/watch");
          }}
          onMouseOver={() => watch.play()}
          onMouseLeave={() => watch.reverse()}
        >
          WATCH
        </MenuButton>

        <MenuButton
          ref={donateBtn}
          onClick={() => {
            closeMenu();
            donate.reverse();
            history.push("/donate");
          }}
          onMouseOver={() => donate.play()}
          onMouseLeave={() => donate.reverse()}
        >
          DONATE
        </MenuButton>
      </div>
    </div>
  );
};

export default Box;
