import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { createUserWithEmailAndPassword } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "./config";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
// import md5 from "md5";

const { WebClient } = require("@slack/web-api");
const token = process.env.SLACK_TOKEN;
const web = new WebClient(token);
delete web["axios"].defaults.headers["User-Agent"];

class Firebase {
  constructor() {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    this.auth = getAuth();
    this.db = getFirestore();
    this.functions = getFunctions();
  }

  // async register(name, email, password) {
  //   const newUser = await createUserWithEmailAndPassword(email, password);

  //   // await newUser.user.updateProfile({
  //   //   displayName: name,
  //   //   photoURL: `https://gravatar.com/avatar/${md5(
  //   //     newUser.user.email
  //   //   )}?d=identicon`,
  //   // });

  //   const { uid, displayName } = newUser.user;
  //   const data = {
  //     id: uid,
  //     name: displayName,
  //     email: newUser.user.email,
  //     photoURL: `https://gravatar.com/avatar/${md5(
  //       newUser.user.email
  //     )}?d=identicon`,
  //     created_at: new Date().getTime(),
  //   };

  //   await this.db.collection("users").doc(uid).set(data);

  //   const notifText = `We have one more user with name: ${newUser.user.displayName} and email: ${newUser.user.email}`;
  //   const notifUserChannelId = "C026B3H3X0S";
  //   await this.sendSlackNotif(notifText, notifUserChannelId);

  //   await this.sendEmailVerification();

  //   return newUser;
  // }

  // async login(email, password) {
  //   await this.auth.signInWithEmailAndPassword(email, password);
  // }

  // async logout() {
  //   await this.auth.signOut();
  // }

  // async resetPassword(email) {
  //   await this.auth.sendPasswordResetEmail(email);
  // }

  // async sendEmailVerification() {
  //   await this.auth.currentUser.sendEmailVerification();
  // }

  // async sendSlackNotif(text, channel) {
  //   await web.chat.postMessage({ text, channel });
  // }

  // async deleteDoc(collectionName, docID) {
  //   await this.db.collection(collectionName).doc(docID).delete();
  // }

  async handleStripeCheckout() {
    const createStripeCheckout = httpsCallable(
      this.functions,
      "createStripeCheckout"
    );

    try {
      const stripe = await loadStripe(
        "pk_live_51Jwq6eJX0VDcRIQx4XLE86UVCRMVs8JXtg4EHT6MRpfMZyUre7aDzbwK6usLIAhBuIGByyInmjkoB8fzkWC3bpoq00TrVqUmIA"
      );
      const { data } = await createStripeCheckout();
      await stripe.redirectToCheckout({ sessionId: data.id });
    } catch (error) {
      console.log("error", { error });
    }
  }
}

const firebase = new Firebase();
export default firebase;
