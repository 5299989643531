/* eslint-disable import/first */
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const Home = lazy(() => import("../src/views/Home"));
const StripeSuccess = lazy(() => import("../src/views/StripeSuccess"));
const StripeCancel = lazy(() => import("../src/views/StripeCancel"));
const Watch = lazy(() => import("./views/Watch"));
const About = lazy(() => import("./views/About"));
const Connect = lazy(() => import("./views/Connect"));
const Artists = lazy(() => import("./views/Artists"));
const Donate = lazy(() => import("./views/Donate"));
const Error404Page = lazy(() => import("./views/404"));
const Support = lazy(() => import("./views/Support"));
const PrivacyPolicy = lazy(() => import("./views/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./views/TermsOfService"));
import Box from "./gsap/Box";
import "./App.css";

import instagram from "./images/instagram.svg";
import facebook from "./images/facebook.svg";
import website from "./images/website.svg";
import { GooeyCircleLoader } from "react-loaders-kit";

function App() {
  // useFavicon("https://cdn.sstatic.net/Sites/stackoverflow/img/favicon.ico");
  return (
    <Suspense fallback={<GooeyCircleLoader loading={true} />}>
      <Router>
        <nav className="z-40 background-red">
          <Box />
        </nav>

        <Switch>
          <Route path="/donation-success">
            <StripeSuccess />
          </Route>
          <Route path="/watch">
            <Watch />
          </Route>
          <Route path="/donation-cancel">
            <StripeCancel />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/about-the-project">
            <About />
          </Route>
          <Route path="/connect-with-us">
            <Connect />
          </Route>
          <Route path="/artists">
            <Artists />
          </Route>
          <Route path="/donate">
            <Donate />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms-of-service">
            <TermsOfService />
          </Route>
          <Route component={Error404Page} />
        </Switch>
        <div className="flex justify-center text-white font-poiret m-4">
          <a
            href="https://www.instagram.com/sublimedancecompany/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="instagram-logo"
              src={instagram}
              className="m-4 w-10 transform hover:scale-110"
              width="10"
              height="10"
            ></img>
          </a>
          <a
            href="https://www.facebook.com/SublimeDanceCompany/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="facebook-logo"
              src={facebook}
              className="m-4 w-10 h-10 transform hover:scale-110"
              width="10"
              height="10"
            ></img>
          </a>

          <a
            href="https://www.sublimedancecompany.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="website-logo"
              src={website}
              className="m-4 w-10 h-10 transform hover:scale-110"
              width="10"
              height="10"
            ></img>
          </a>
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
